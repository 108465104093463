<template>
  <v-container id="resource-page">
    <div class="text-center py-5">
      <div class="text-h4 font-weight-regular">{{ isLocked ? "Edit" : "View" }} Resource</div>
      <div class="title my-1" v-if="resource">{{ resource.name }}</div>
    </div>

    <div class="text-center py-15 grey--text" v-if="loading">
      <v-progress-circular indeterminate size="50" color="grey" />
      <div class="py-2 caption">Loading resource...</div>
    </div>

    <template v-else-if="resource">
      <v-row justify="center">
        <v-col cols="12" lg="10" xl="8">
          <v-card>
            <div class="lighten-3 py-2 px-5">
              <div class="title">Permissions</div>
              <div class="body-2">Control who can access this resource.</div>
            </div>
            <v-divider />
            <v-card-text>
              <v-checkbox
                inset
                hide-details
                class="mt-0 ma-2"
                style="font-weight: bold"
                v-for="role in userRoles"
                :key="role"
                :label="role"
                :input-value="resource.roles.includes(role)"
                @change="(val) => handleRoleChange(role, val)"
                :disabled="isLocked || loading || isPublicResource"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" lg="10" xl="8">
          <v-card>
            <div class="lighten-3 py-2 px-5">
              <div class="title">Privileges</div>
              <div class="body-2">Control which resources one can access.</div>
            </div>
            <v-divider />
            <v-card-text>
              <v-treeview
                v-model="selection"
                :open.sync="initiallyOpen"
                :items="privilegeTree"
                selection-type="leaf"
                selectable
                open-on-click
              >
                <template v-slot:append="{ item, leaf }">
                  <span class="caption teal--text d-none d-md-block" v-if="!leaf">
                    {{ item.id }}{{ item.terminal ? "" : "/**" }}
                  </span>
                </template>
                <template v-slot:label="{ item, leaf }">
                  <span :style="{ color: methodColors[item.name], fontWeight: 500 }" v-if="leaf">
                    {{ item.name }}
                  </span>
                  <span style="color: black" v-else>{{ item.name }}</span>
                </template>
              </v-treeview>
            </v-card-text>

            <v-divider />
            <v-card-actions class="lighten-4 pa-3 px-6" v-if="!isLocked">
              <v-btn @click="makePrivilegeTree" class="px-10">Reset</v-btn>
              <v-spacer />
              <v-btn color="primary" class="px-10" @click="handlePrivilegeChange" :loading="saving">
                <v-icon>mdi-save</v-icon> Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import debounce from "lodash.debounce";
import * as api from "@/constants/api";
import { USER_ROLES } from "@/constants/roles";

export default {
  metaInfo: { title: "Edit Resource" },
  data: () => ({
    loading: false,
    resource: null,
    saving: false,
    privilegeTree: [],
    selection: [],
    initiallyOpen: ["/api"],
  }),
  computed: {
    resourceId() {
      return Number(this.$route.params.id);
    },
    userRoles: () => USER_ROLES,
    httpMethods: () => ["GET", "HEAD", "POST", "PUT", "PATCH", "DELETE", "OPTIONS", "TRACE"],
    methodColors: () => ({
      GET: "#61affe",
      POST: "#49cc90",
      PUT: "#fca130",
      DELETE: "#f93e3e",
      HEAD: "teal",
      PATCH: "teal",
      OPTIONS: "teal",
      TRACE: "teal",
    }),
    isLocked() {
      return (
        !this.verifyPrivilege(api.USER_RESOURCE_UPDATE) || !this.resource || this.resource.locked
      );
    },
    isPublicResource() {
      return this.resource && this.resource.name === "Public";
    },
  },
  watch: {
    resourceId() {
      this.fetchData();
    },
  },
  created() {
    this.saveResource = debounce(this.saveResource, 500, { leading: true });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        await this.$nextTick();
        const resp = await this.$axios.get(api.USER_RESOURCE_GET, {
          params: { resourceId: this.resourceId },
        });
        this.resource = resp.data.data;
        this.makePrivilegeTree();
      } catch (err) {
        console.error(err);
        this.$iziToast.showError(err);
        this.$router.push({ name: "resource.index" });
      } finally {
        this.loading = false;
      }
    },
    async saveResource(data) {
      if (this.isLocked) {
        return;
      }
      try {
        this.saving = true;
        await this.$axios.put(api.USER_RESOURCE_UPDATE, data);
        this.$iziToast.success({
          title: "Saved",
          message: this.resource.name,
        });
        return true;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.saving = false;
      }
    },
    convertPrivilegeToRegex({ httpMethod, antPath }) {
      const method = httpMethod || this.httpMethods.join("|") + "|any";
      const path = ("" + antPath)
        .split("**")
        .map((s) => s.split("*").join("[^/]+"))
        .map((s) => s.split("?").join("."))
        .join(".*");
      return `(^(${method})\\t${path}$)`;
    },
    makePrivilegeTree() {
      if (!this.resource) return;

      // build selecetion matcher
      const matcher = this.resource.privileges.map(this.convertPrivilegeToRegex).join("|");
      const regex = matcher && new RegExp(matcher);

      // build public route matcher
      const publicMatcher = this.isPublicResource
        ? null
        : this.resource.privileges
            .filter((x) => !x.requireLogin)
            .map(this.convertPrivilegeToRegex)
            .join("|");
      const publicRegex = publicMatcher && new RegExp(publicMatcher);

      // build privilege tree
      const tree = [];
      const selected = [];
      Object.entries(this.$auth.privileges).forEach(([route, methods]) => {
        if (route.includes("/ping")) {
          return;
        }
        let children = tree;
        let pathId = "";
        let next = null;
        route
          .split("/")
          .filter((x) => !!x)
          .forEach((part) => {
            pathId += "/" + part;
            next = children.find((x) => x.id === pathId);
            if (!next) {
              next = {
                id: pathId,
                name: part,
                children: [],
              };
              children.push(next);
            }
            children = next.children;
          });

        next.terminal = true;
        if (!methods || !methods.length) {
          methods = this.httpMethods;
        }
        methods.forEach((name) => {
          const id = `${name}\t${pathId}`;
          if (regex && regex.test(id)) {
            selected.push(id);
          }
          const isPublic = publicRegex && publicRegex.test(id);
          const disabled = this.isLocked || (!this.isPublicResource && isPublic);
          children.push({
            id,
            name,
            disabled,
          });
        });
      });

      // sort privilege tree
      const sortChildren = (item) => {
        if (!item.children) return;
        item.children.forEach(sortChildren);
        item.disabled = !item.children.find((v) => !v.disabled);
        item.children = item.children.sort((a, b) => {
          if (!a.children && b.children) return -1;
          if (a.children && !b.children) return 1;
          return a.name.localeCompare(b.name);
        });
      };
      tree.forEach(sortChildren);

      this.privilegeTree = tree;
      this.selection = selected;
    },
    async handleRoleChange(role, change) {
      const roles = this.resource.roles.filter((x) => x !== role);
      if (change) {
        roles.push(role);
      }
      const success = await this.saveResource({
        id: this.resourceId,
        roles: roles,
      });
      if (success) {
        this.resource.roles = roles;
      }
    },
    async handlePrivilegeChange() {
      const privileges = this.selection.map((item) => {
        let [httpMethod, antPath] = item.split("\t");
        antPath = antPath.replace(/\{[^}]+\}/g, "*");
        return { httpMethod, antPath };
      });
      const success = await this.saveResource({
        id: this.resourceId,
        privileges,
      });
      if (success) {
        this.resource.privileges = privileges;
      }
    },
  },
};
</script>

<style lang="scss">
#resource-page {
  .v-label,
  .v-label--is-disabled {
    font-weight: 500;
    color: #333 !important;
  }
}
</style>
